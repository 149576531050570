import Projects from "./components/Projects";
import Hero from "./components/Hero";
import Footer from "./components/Footer";


function App() {


  return (
    <div className="App">

      <Hero />
      <Projects />
      <hr style={{ border: '1px solid #353434' }} />
      <Footer />
    </div>
  );
}

export default App;
