
import '../App.css';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { useState, useEffect } from 'react'
import { ThreeDots, Circles, Puff } from 'react-loader-spinner'
import { CiShare1 } from "react-icons/ci";
import app from '../Firebase'
import { collection, getFirestore, getDoc, doc, onSnapshot, query, orderBy, } from "firebase/firestore"
import { FakeData } from '../FakeDemo'
import notFoundDev from '../assets/dev.gif'



function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}



export default function Projects() {
    const [Projects, setProjects] = useState([])
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const ReadDataFunction = async () => {
            const db = getFirestore(app)
            const EventscollectionRef = collection(db, "DevSubmission");
            const EventsOrderedQuery = query(EventscollectionRef, orderBy("Sent_At", "desc"));

            const unsubscribeEvents = onSnapshot(EventsOrderedQuery, (querySnapshot) => {
                const newData = querySnapshot.docs.map((doc) => ({ ...doc.data(), docId: doc.id }));
                // setProjects(newData)
                setProjects(shuffleArray(newData)); // Shuffle the array before setting it

            });
            return () => {
                unsubscribeEvents()

            }
        };

        ReadDataFunction();


    }, []);


    const pageSize = 6;
    const [itemsToShow, setItemsToShow] = useState(pageSize);
    const [imageLoaders, setEmojiImageLoaders] = useState(Projects.map(() => true));

    const handleImageLoaders = (index) => {
        setEmojiImageLoaders(prevLoaders => prevLoaders.map((loader, i) => (i === index ? false : loader)));
    };

    const handleLoadMore = () => {
        setItemsToShow(itemsToShow + pageSize);
    };


    const LoadMoreFunction = () => {
        if (Projects.length !== 0 && Projects.length >= 6) {
            return (
                Projects.length !== 0 && Projects.filter(project =>
                    project.DeveloperName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    project.Description.toLowerCase().includes(searchTerm.toLowerCase())
                ).length !== 0 &&
                <div className='Load-More-Button-Div'>
                    {itemsToShow >= Projects.length ? <p></p> :
                        <Button onClick={handleLoadMore} className='load-more-button'>Load More...</Button>
                    }
                </div>
            )

        }
    }



    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };



    return (
        <div className="App">

            <div className='container-fluid'>
                <div className='row'>
                    <div className='search-input-div'>
                        <input
                            type="text"
                            placeholder="Search by name or skills..."
                            value={searchTerm}
                            className='search-bar'
                            onChange={handleSearchChange}
                        />
                    </div>


                    {
                        Projects.length === 0 ?
                            <div className='loader-div'>
                                <Circles color='#FFBE19' />
                            </div> :

                            Projects.filter(project =>
                                project.DeveloperName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                project.Description.toLowerCase().includes(searchTerm.toLowerCase())
                            ).length === 0 ? (
                                <div className='not-found-div'>
                                    <img src={notFoundDev} className='not-found-gif' />
                                    <p className='not-found'>Not found.</p>
                                </div>

                            ) :
                                Projects.filter(project =>
                                    project.DeveloperName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                    project.Description.toLowerCase().includes(searchTerm.toLowerCase())
                                ).slice(0, itemsToShow).map((item, index) => {
                                    return (
                                        <div className="col-lg-4 mb-4 col-md-6 col-sm-12" key={index}>

                                            {Projects?.length == 0 ?

                                                <Card className='card h-100'>
                                                    {
                                                        imageLoaders[index] && (
                                                            <div className='spinner'
                                                                style={{}}>
                                                                <ThreeDots color='#FFBE19' />
                                                            </div>
                                                        )
                                                    }

                                                    <div className='loader-div'>
                                                        <Puff color='#FFBE19' />
                                                    </div>
                                                    <Card.Body>


                                                    </Card.Body>


                                                </Card>

                                                :
                                                <Card className='card h-100'>
                                                    {
                                                        imageLoaders[index] && (
                                                            <div className='spinner'
                                                                style={{}}>
                                                                <ThreeDots color='#FFBE19' />
                                                            </div>
                                                        )
                                                    }
                                                    <Card.Img
                                                        variant="top"
                                                        src={item.imgUrl}
                                                        onLoad={() => handleImageLoaders(index)}
                                                        key={index}
                                                        className="thumb-img"
                                                        draggable={false}

                                                    />
                                                    <Card.Body>
                                                        <Card.Title className='name'>
                                                            {item.DeveloperName}
                                                            <img
                                                                alt=""
                                                                className='country-flag'
                                                                src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${item.country}.svg`}
                                                            />

                                                        </Card.Title>
                                                        <Card.Text className='description'>
                                                            {item.Description}
                                                        </Card.Text>

                                                    </Card.Body>
                                                    <div className='button-div'>
                                                        <a href={item.Link} target='_blank'>
                                                            <Button className='button'>
                                                                View Portfolio
                                                            </Button>
                                                        </a>

                                                    </div>

                                                </Card>
                                            }



                                        </div>
                                    )
                                })

                    }


                </div>
            </div>
            {LoadMoreFunction()}
        </div>
    )
}
