// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use

const firebaseConfig = {
    apiKey: "AIzaSyApqe-RjHpZxtl1J0na9PCYvD0RAUw2WuY",
    authDomain: "devfoliohub.firebaseapp.com",
    projectId: "devfoliohub",
    storageBucket: "devfoliohub.appspot.com",
    messagingSenderId: "1022853116633",
    appId: "1:1022853116633:web:ccbf8be818ace3990d84c8",
    measurementId: "G-GRVMCP47LW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);




export default app