import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import PeepsImages from './PeepsImages';
import { CiLinkedin } from "react-icons/ci";


export default function Footer() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <div className='hero-main-div row'>
            <div className='col-lg-12 hero-text-div'>



                <Button onClick={handleShow} className='button sumbit-portfolio'>
                    Submit Your Portfolio
                </Button>

                <p className='built-by-Randy'>
                    Built By <a
                        className='randy-davoh'
                        href='https://www.linkedin.com/in/randy-davoh/'
                        target='_blank'
                    >Randy Davoh
                        <img
                            alt=""
                            className='country-flag'
                            src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/GH.svg`}
                        /></a>
                </p>


                <Modal show={show} onHide={handleClose} >
                    <Modal.Header closeButton className='modal-header'>
                        <Modal.Title className='modal-title'>
                            PORTFOLIO SUBMISSION
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='modal-body'>
                        Send an email to <a href='mailto:randydavoh@yahoo.com' className='email'> randydavoh@yahoo.com </a>
                        to sumbit
                        your portfolio for review. <br /> <br />

                        <p className='email-instructions-text'>EMAIL INSTRUCTIONS:</p>
                        1. <span style={{ fontWeight: 'bold' }}>Email subject</span> should be <span className='instructions-highlight'>'DEVFOLIOHUB PROJECT SUBMISSION (YOUR FULL NAME)'.</span>  <br /> <br />
                        2. <span style={{ fontWeight: 'bold' }}>Email message </span> should contain your info in this ordered format: <br />
                        A. <span className='instructions-highlight'> Your Full Name.</span>  <br /> <br />
                        B. <span className='instructions-highlight'>A Short Description of Yourself (Not more than 50 words). <br /> <br /></span>
                        C. <span className='instructions-highlight'>Country of Residence. <br /> <br /></span>
                        D. <span className='instructions-highlight'>Portfolio Demo Link.</span> <br /> <br />
                        E. <span className='instructions-highlight'>Attach Portfolio Desktop-Sized Thumbnail Image (Size Limit: 350KB).</span> <br /> <br />

                        <p style={{ textAlign: 'center', fontWeight: 'bold' }}>You will be notified once it's reviewed, approved and showcased.</p>
                    </Modal.Body>

                </Modal>


            </div>
            {/* <PeepsImages /> */}
        </div>
    )
}
