import React from 'react'
import { FaCode } from "react-icons/fa6";

export default function Hero() {
    return (
        <div className='hero-main-div row'>
            <div className='col-lg-12 hero-text-div'>
                <h1 className='hero-text'>
                    <span className='brandName'>DevFolioHub <FaCode className='code-icon' /></span> <br />
                </h1>
                <p className='hero-text-mini'>
                    Fuel Your Creativity <span className='hero-strokes'>| </span>
                    Get Inspiration For Your Next Project <span className='hero-strokes'>| </span>
                    Hire Developers To Bring Your Ideas To Life
                </p>
            </div>
        </div>
    )
}
